<template>
    <div class="memos-search">
        <memod-list :list-endpoint="listEndpoint" :no-memos-found-message="noMemosFoundMessage" :pagination-format="false" />
    </div>
</template>

<script>
export default {
    name: "MemosSearch",
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/feed.svg"),
                title: "Nothing found",
                text: "No Results. Try searching for another title, topic, writer or keyword."
            }
        }
    }
}
</script>
